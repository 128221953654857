/* eslint-disable camelcase */
import { useTranslations } from "@app-i18n/useTranslations";
import { useAppRestClient } from "@app-shell-rest/configurations";
import { type IDeviceConfiguration } from "@app-shell-rest/configurations.interfaces";
import { useGlobalStore } from "@app-shell-store/global";
import { useLangStore } from "@app-shell-store/lang";
import { AppInsightsEvent } from "@app-utilities/observability-events";
import usePersistentStorage from "@app-utilities/persistent-storage";
import useTokenUtilities from "@app-utilities/token-utilities";
import { QButton, QSpinner, useToastController } from "@qamf/lighthouse";
import { useAppSettings, useAppStoreManager, useObservability } from "@qamf/shell-app-sdk";
import { useClipboard } from "@vueuse/core";
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "Pairing",
	components: {
		QButton,
		QSpinner
	},
	setup() {
		const { translateKey, setLanguage, getIsoCode } = useTranslations();
		const { qportalUrl } = useAppSettings();
		const { getDeviceCode, verifyToken, getCenterInfo } = useAppRestClient();
		const { setItem } = usePersistentStorage();
		const { setSystemId } = useAppStoreManager();
		const { parseTokenJWT } = useTokenUtilities();
		const { trackEvent } = useObservability();
		const { setupI18nApp } = useLangStore();
		const globalStore = useGlobalStore();
		const router = useRouter();
		const isLoading = ref(false);
		const { copy } = useClipboard();
		const { show: showToast, remove: removeToast } = useToastController();
		const copyToastComponent = ref<symbol>();
		const durationTimeCodeInSeconds = ref(600);
		const currentTimeCode = ref<number>(durationTimeCodeInSeconds.value);
		const intervalCountdown = ref<ReturnType<typeof setInterval>>(setInterval(() => { }, 0));
		const timeoutCountdown = ref<ReturnType<typeof setTimeout>>(setTimeout(() => { }, 0));
		const verifyTokenPolling = ref<ReturnType<typeof setTimeout>>(setTimeout(() => { }, 0));

		const isTerminalPaired = ref(false);
		const deviceInfo = ref<IDeviceConfiguration | null>(null);
		const pairingTrackingKey = ref<string>("");
		const systemPairedInfo = reactive({
			systemId: null as number | null,
			terminalNumber: null as number | null,
			systemName: null as string | null
		});

		const minutes = computed(() => {
			return Math.floor(currentTimeCode.value / 60).toString().padStart(2, "0");
		});

		const seconds = computed(() => {
			return (currentTimeCode.value % 60).toString().padStart(2, "0");
		});

		const instructionsQPortal = () => {
			const linkQPortal = qportalUrl.value;
			const instruction = translateKey("pairing_instructions_step_1_open_qportal", {
				linkQPortal: `<a href="${linkQPortal}" target="_blank">QPortal</a>`
			});
			return instruction;
		};

		const updateCountdown = () => {
			if (typeof intervalCountdown.value === "undefined") return;
			currentTimeCode.value -= 1;
			if (currentTimeCode.value <= 0)
				clearInterval(intervalCountdown.value);
		};

		const initCountdown = () => {
			currentTimeCode.value = durationTimeCodeInSeconds.value;
			intervalCountdown.value = setInterval(updateCountdown, 1000);
		};

		const resetTimers = () => {
			clearTimeout(timeoutCountdown.value);
			clearInterval(intervalCountdown.value);
		};

		const stopPollingVerifyToken = () => {
			if (isTerminalPaired.value)
				clearTimeout(verifyTokenPolling.value);
		};

		const completeTerminalPairing = async(accessToken: string) => {
			const { center_id, terminal_number } = parseTokenJWT(accessToken);
			const response = await getCenterInfo(center_id, terminal_number);
			if (!response.fetchResponse?.ok || !response.data) return;

			systemPairedInfo.systemName = response.data.CenterName;
			systemPairedInfo.systemId = center_id;
			systemPairedInfo.terminalNumber = terminal_number;
			const isBrowserPreferred = globalStore.isBrowserPreferred;

			setSystemId(center_id);
			setItem("systemId", center_id);
			setItem("terminalNumber", terminal_number);
			setItem("terminalPaired", true);
			setItem("browserPreferred", isBrowserPreferred);

			const companyLanguage = response.data.CompanyLanguage ?? "en-US";
			await setLanguage(companyLanguage);
			await setupI18nApp(getIsoCode());

			isTerminalPaired.value = true;

			stopPollingVerifyToken();

			trackEvent(AppInsightsEvent.TerminalPairingEnd, {
				pairingKey: pairingTrackingKey.value
			});
		};

		const startPollingVerifyToken = () => {
			clearTimeout(verifyTokenPolling.value);

			const loop = () => {
				verifyTokenPolling.value = setTimeout(async() => {
					if (!deviceInfo.value) return;

					const deviceCode = deviceInfo.value.device_code;
					const response = await verifyToken(deviceCode);
					if (response && response.fetchResponse?.ok && response.data) {
						setItem("terminalToken", response.data);
						const accessToken = response.data.access_token;
						completeTerminalPairing(accessToken);
					} else
						loop();
				}, 5000);
			};

			loop();
		};

		const getPairingCode = async() => {
			stopPollingVerifyToken();

			isLoading.value = true;

			const response = await getDeviceCode();
			if (!response.fetchResponse?.ok || !response.data) return;
			deviceInfo.value = response.data;
			currentTimeCode.value = response.data.expires_in ?? durationTimeCodeInSeconds;
			durationTimeCodeInSeconds.value = currentTimeCode.value;

			isLoading.value = false;

			pairingTrackingKey.value = `${deviceInfo.value.device_code}-${Date.now()}`;

			initCountdown();
			startPollingVerifyToken();

			trackEvent(AppInsightsEvent.TerminalPairingStart, {
				pairingKey: pairingTrackingKey.value
			});
		};

		const formatTimerCountdown = () => {
			const timeSep = useLangStore().timeSeparator ?? ":";
			return `${minutes.value}${timeSep}${seconds.value}`;
		};

		const generateNewPairingCode = async() => {
			resetTimers();
			isLoading.value = true;
			await getPairingCode();
		};

		const onNextButtonClick = async() => {
			return router.push({ name: "login" });
		};

		const labelTerminalPaired = () => {
			if (!systemPairedInfo.terminalNumber) return;
			return translateKey("terminal_number_paired_with", {
				terminalNumber: systemPairedInfo.terminalNumber
			});
		};

		const labelSystemInfo = () => {
			if (!systemPairedInfo.systemId || !systemPairedInfo.systemName) return;
			return translateKey("system_name_id_number", {
				systemName: systemPairedInfo.systemName,
				systemId: systemPairedInfo.systemId
			});
		};
		const copyUserCode = () => {
			if (!deviceInfo.value || typeof copyToastComponent.value !== "undefined") return;

			copy(deviceInfo.value.user_code);
			copyToastComponent.value = showToast?.({
				props: {
					variant: "info",
					pos: "top-end",
					interval: 100,
					body: translateKey("code_copied_clipboard")
				}
			});
			setTimeout(() => {
				if (copyToastComponent.value) {
					removeToast?.(copyToastComponent.value);
					copyToastComponent.value = undefined;
				}
			}, 3000);
		};

		watch(
			() => currentTimeCode.value,
			() => {
				if (currentTimeCode.value > 0) return;
				timeoutCountdown.value = setTimeout(generateNewPairingCode, 5000);
			}
		);

		return {
			currentTimeCode,
			isTerminalPaired,
			isLoading,
			deviceInfo,
			copyUserCode,
			translateKey,
			getPairingCode,
			generateNewPairingCode,
			instructionsQPortal,
			formatTimerCountdown,
			onNextButtonClick,
			labelTerminalPaired,
			labelSystemInfo
		};
	}
});
